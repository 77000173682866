<template>
    <div class="container">
        <van-notice-bar
            v-if="message"
            left-icon="volume-o"
            :text="message"
            @click="showDialog"
        />
        <main-banner type="INDEX_BANNER" />

        <div v-for="(item, index) in home" :key="index" class="item">
            <div class="item-title">
                <span>{{ item.title }}</span>
                <span class="item-title-english"> / {{ item.english }}</span>
            </div>
            <services v-if="item.type === 'services'" class="item-content" />
            <inquiry v-if="item.type === 'inquiry'" class="item-content" />
            <contact v-if="item.type === 'contact'" class="item-content" />
        </div>
        <div class="item">
            <van-cell-group class="content">
                <van-cell
                    size="large"
                    value="隐私政策"
                    is-link
                    style="font-weight: bold"
                    to="privacy"
                />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { Dialog } from "vant";
import MainBanner from "@/components/MainBanner";
import Services from "./components/Services";
import Inquiry from "./components/Inquiry";
import Contact from "./components/Contact";
import { home } from "./json";
import { getNotification } from "@/api/notification";
import { ref } from "vue";

export default {
    name: "Home",
    components: { MainBanner, Services, Inquiry, Contact },
    setup() {
        const message = ref("");
        async function getNotify() {
            let data = await getNotification("NOTIFICATION_INDEX");
            if (data) {
                if (!data.enabled) return;
                message.value = data.content;
            }
        }
        getNotify();
        function showDialog() {
            Dialog({
                title: "重要通知",
                message: message.value,
            });
        }
        return { home, message, getNotify, showDialog };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.container {
    background-color: #f2f2f2;
    .item {
        background-color: #ffffff;
        margin-bottom: 10px;
        .item-title {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            padding: 16px;
        }
        .item-title-english {
            font-size: 13px;
            font-weight: initial;
            color: #a0a0a0;
            padding-left: 3px;
        }
        .item-content {
            .flex(space-between);
            padding: 0 16px 10px;
            flex-wrap: wrap;
        }
    }
}
</style>
