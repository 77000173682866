<template>
    <div>
        <div
            v-for="(item, index) in services"
            :key="index"
            class="services-item"
            @click="item.to ? $router.push(item.to) : item?.click?.()"
        >
            <van-image :src="item.img" fit="contain" />
            <span>{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
import { services } from "../json";

export default {
    setup() {
        return { services };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.services-item {
    .flex(center,center,column);
    flex: 1;
    font-size: 12px;
    .van-image {
        display: block;
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
    }
}
</style>