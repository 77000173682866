import { isDevelop, toContact, callMobile } from "@/utils";
import settings from "@/settings"
export const home = [
    {
        title: "基础服务",
        english: "Basic services",
        type: "services",
    },
    {
        title: "自主查询",
        english: "Independent inquiry",
        type: "inquiry",
    },
    {
        title: "联系我们",
        english: "Contact us",
        type: "contact",
    },
]
export const services = [
    {
        title: "安装/维修",
        img: require('@/assets/icon/fix.png'),
        click: () => window.location.href = "http://test.wangerdi.cn/dist"
    },
    {
        title: "激活",
        img: require('@/assets/icon/repair.png'),
        to: "fix?active=1",

    },
    {
        title: "延保",
        img: require('@/assets/icon/fault.png'),
        click: isDevelop
    },
]
export const inquiry = [
    {
        title: "进度查询",
        text: "查询安装维修进度",
        img: require('@/assets/home/jdcx.png'),
        color: "#58a9da",
        to: "event?active=0"
    },
    {
        title: "服务政策",
        text: "给你更安心的服务",
        img: require('@/assets/home/fwzc.png'),
        color: "#fe704a",
        to: "policy?active=0"
    },
    {
        title: "收费标准",
        text: "装锁服务的收费标准",
        img: require('@/assets/home/sfbz.png'),
        color: "#717fdc",
        to: "policy?active=1"
    },
    {
        title: "保养日历",
        text: "历史保养记录查询",
        img: require('@/assets/home/byrz.png'),
        color: "#5698ff",
        click: isDevelop
    },
]
export const contact = [
    {
        title: "在线客服",
        text: "9:00 - 17:00",
        img: require('@/assets/home/service.png'),
        color: "linear-gradient(180deg, #66D2FF 0%, #36A6FF 100%)",
        btn: "立即咨询",
        click: toContact
    },
    {
        title: "服务热线",
        text: settings.servicePhone,
        img: require('@/assets/home/mobile.png'),
        color: "linear-gradient(180deg, #FFD666 0%, #FFAC36 100%)",
        btn: "立即咨询",
        click: () => callMobile(settings.servicePhone)
    }
]