<template>
    <div>
        <div
            v-for="(item, index) in inquiry"
            :key="index"
            class="inquiry-item"
            @click="item.to ? $router.push(item.to) : item?.click?.()"
        >
            <van-image :src="item.img" />
            <div class="inquiry-content">
                <span class="inquiry-title" :style="{ color: item.color }">
                    {{ item.title }}
                </span>
                <span class="inquiry-text">{{ item.text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { inquiry } from "../json";

export default {
    setup() {
        return { inquiry };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.inquiry-item {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    .van-image {
        display: block;
        width: 100%;
    }
    .inquiry-content {
        .flex(center,flex-start,column);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 2%;
        margin: auto;
        white-space: nowrap;
        transform: scale(0.75);
        .inquiry-title {
            font-size: 4.8vw;
            line-height: 1.4;
            font-weight: bold;
        }
        .inquiry-text {
            font-size: 3.2vw;
            line-height: 1.4;
            color: #999999;
        }
    }
}
</style>