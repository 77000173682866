import request from '@/utils/request'
let notification = {}

// 根据通知编码 获取通知内容
export async function getNotification(code) {
    // 若缓存有值，则直接返回
    if (notification[code]) return Promise.resolve(notification[code]);
    let { data } = await request({
        url: '/app/notification/findByCode/' + code,
        method: 'get',
    })
    notification[code] = data;
    return Promise.resolve(data);
}
