<template>
    <van-swipe
        v-if="banner.length > 1"
        :autoplay="3000"
        indicator-color="black"
        :height="height"
        class="banner"
    >
        <van-swipe-item v-for="item in banner" :key="item">
            <van-image
                :src="getFile(item.image, 'ADVERT')"
                width="100%"
                height="100%"
                fit="cover"
                @click="href(item.url)"
            />
        </van-swipe-item>
    </van-swipe>
    <van-image
        v-else-if="banner[0]"
        :src="getFile(banner[0].image, 'ADVERT')"
        width="100%"
        :height="height"
        fit="cover"
        @click="href(banner[0].url)"
    />
</template>

<script>
import { reactive, toRefs } from "vue";
import { getFile, go } from "@/utils";
import { advertList } from "@/api/advert";

export default {
    name: "MainBanner",
    props: {
        type: { type: String, require: true },
        height: { default: 150 },
    },
    setup(props) {
        let state = reactive({
            banner: [],
        });

        // 获取广告列表
        async function getBanner() {
            state.banner = await advertList(props.type);
        }
        // 跳转网页或页面
        function href(url) {
            if (!url) return;
            var strRegex = new RegExp(
                "^([hH][tT]{2}[pP]:\\/\\/|[hH][tT]{2}[pP][sS]:\\/\\/|www\\.)(([A-Za-z0-9-~]+)\\.)+([A-Za-z0-9-~\\/])+$"
            );
            strRegex.test(url) ? (window.location.href = url) : go(url);
        }

        getBanner();

        return {
            ...toRefs(state),

            href,

            getFile,
        };
    },
};
</script>

<style lang="less" scoped>
.banner {
    width: 100%;
    display: block;
}
</style>
