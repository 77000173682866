<template>
    <div>
        <div
            v-for="(item, index) in contact"
            :key="index"
            class="contact-item"
            @click="item?.click?.()"
        >
            <div class="contact-content">
                <div class="contact-title">{{ item.title }}</div>
                <div class="contact-text">{{ item.text }}</div>
                <van-button
                    class="contact-btn"
                    size="mini"
                    round
                    :color="item.color"
                >
                    {{ item.btn }}
                </van-button>
            </div>
            <van-image :src="item.img" width="16vw" height="16vw" />
        </div>
    </div>
</template>

<script>
import { contact } from "../json";

export default {
    setup() {
        return { contact };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.contact-item {
    .flex(space-between);
    width: calc(50% - 5px);
    margin-bottom: 10px;
    position: relative;
    box-shadow: 2px 2px 8px #ccc;
    border-radius: 5px;
    padding: 3vw;
    padding-left: 4vw;
    white-space: nowrap;
    overflow: hidden;
    .contact-content {
        width: 0;
        .contact-title {
            font-size: 3.5vw;
            font-weight: bold;
        }
        .contact-text {
            font-size: 3.2vw;
            color: #999999;
            transform-origin: 0 50%;
            transform: scale(0.8);
        }
        .contact-btn {
            padding: 0 10px;
            transform-origin: 0 50%;
            transform: scale(0.8);
        }
    }
}
</style>