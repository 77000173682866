import request from '@/utils/request'
let advert = {}

// 根据广告位置编码 获取广告列表
export async function advertList(code) {
    // 若缓存有值，则直接返回
    if (advert[code]) return Promise.resolve(advert[code]);
    let { data } = await request({
        url: '/app/advert/list',
        method: 'get',
        params: { positionCode: code }
    })
    advert[code] = data;
    return Promise.resolve(data);
}
